import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import Resource from "../models/resource";

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export function present(value) {

	if (value instanceof Resource) {
		return !value.empty;
	}

	if (value === null || value === undefined) return false;
	if (typeof value === 'string' || Array.isArray(value)) return value.length > 0;
	if (typeof value === 'object') return Object.keys(value).length > 0;

	return Boolean(value);
}



export function blank(value) {
	return !present(value);
}

export const fetcher = (...args) => fetch(...args).then(res => res.json())
