
export default class ArmourAPI {

	static endpoint = 'https://armour.construction/api/v2';
	static sessionKey = 'arm_session';

	static async newSession(email, password) {
		const response = await fetch(`${this.endpoint}/session`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email, password }),
		});

		if (!response.ok) {
			throw new Error('Failed to create session');
		}

		return await response.json();
	}

}
