import * as React from "react";

import useSessionToken from "../swr/use-session-token";


export default function RootPage() {

	const {user, isLoading, isError, mutate} = useSessionToken();

	console.debug(user)

	return (
		<div>
			<h1>ARMOUR CONSTRUCTION 2025</h1>
		</div>
	);
}
