import * as React from "react";
import * as ReactDOM from "react-dom/client";

import RootPage from "./routes/root";
import ClientPage from "./routes/client";
import SignInPage from "./routes/auth/sign-in";
import ForgotPasswordPage from "./routes/auth/forgot-password";
import {Switch, Route} from "wouter";

ReactDOM.createRoot(document.getElementById("root")).render(
	<Switch>
		<Route path="/">
			<RootPage />
		</Route>

		<Route path="/auth/sign-in">
			<SignInPage />
		</Route>

		<Route path="/auth/forgot-password">
			<ForgotPasswordPage />
		</Route>

		<Route path="/client/:lead_xid">
			{params => <ClientPage leadXID={params.lead_xid} />}
		</Route>

		<Route path="*">
			{(params) => `404, Sorry the page ${params["*"]} does not exist!`}
		</Route>
	</Switch>
);
