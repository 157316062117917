import React, { useState } from 'react';
import AuthLayout from "../../components/layouts/auth";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../components/ui/form";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import ArmourAPI from "../../lib/armour-api";
import { Alert, AlertDescription } from "../../components/ui/alert";
import { XCircle } from 'lucide-react';
import Cookies from "js-cookie";
import {Link} from "wouter";
import { navigate } from "wouter/use-browser-location";

const formSchema = z.object({
	email: z.string().email(),
	password: z.string().min(5, {
		message: "Password must be at least 5 characters long."
	}),
})

export default function SignInPage() {

	const [error, setError] = useState(null);

	const form = useForm({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: "",
			password: ""
		},
	})

	async function onSubmit(values) {
		setError(null);
		try {
			const { session, user_xid } = await ArmourAPI.newSession(values.email, values.password)
			Cookies.set(ArmourAPI.sessionKey, session, { expires: 7 });
			navigate("")
		} catch (err) {
			setError("Failed to sign in. Please check your credentials and try again.");
		}
	}

	return (
		<AuthLayout>
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<Card className="w-full">
						<CardContent className={"space-y-4 pt-6"}>
							{error && (
								<Alert variant="destructive">
									<XCircle className="h-4 w-4" />
									<AlertDescription>{error}</AlertDescription>
								</Alert>
							)}

							<FormField
								control={form.control}
								name="email"
								render={({field}) => (
									<FormItem>
										<FormLabel>Email Address</FormLabel>
										<FormControl>
											<Input placeholder="" {...field} />
										</FormControl>
										<FormMessage/>
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="password"
								render={({field}) => (
									<FormItem>
										<FormLabel>Password</FormLabel>
										<FormControl>
											<Input placeholder="" type={"password"} {...field} />
										</FormControl>
										<FormMessage/>
									</FormItem>
								)}
							/>
						</CardContent>
						<CardFooter>
							<div className={"w-full flex justify-between"}>
								<div>
									<Button className="w-full" variant={"yellow"} type="submit">Sign in</Button>
								</div>
								<div>
									<Link to={"/auth/forgot-password"}>
										<Button variant="link">Forgot your password?</Button>
									</Link>
								</div>
							</div>
						</CardFooter>
					</Card>
				</form>
			</Form>
		</AuthLayout>
	);
}

