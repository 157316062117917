import {present} from "../lib/utils";
import dayjs from "dayjs";

export default class Resource {
	constructor(payload = {}) {

		this.dto = {}
		this.empty = true;

		// Check if payload is present
		if (present(payload)) {
			this.dto = payload;
			this.empty = false;
		}
	}

	get present() {
		return !this.empty;
	}

	get xid() {
		return this.dto.xid;
	}

	get createdAt() {
		return dayjs(this.dto.created_at);
	}

	get updatedAt() {
		return dayjs(this.dto.updated_at);
	}
}
