import * as React from "react";
import {SidebarInset, SidebarProvider, SidebarTrigger} from "../components/ui/sidebar";
import {BidSidebar} from "../components/bid/bid-sidebar";
import {Separator} from "../components/ui/separator";
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList, BreadcrumbPage,
	BreadcrumbSeparator
} from "../components/ui/breadcrumb";
import {Card, CardContent} from "../components/ui/card";
import {Button} from "../components/ui/button";
import {ArrowFatRight, Mailbox, MapPin, Phone} from "@phosphor-icons/react";

import useLead from "../swr/use-lead";
import useUser from "../swr/use-user";
import useClient from "../swr/use-client";


export default function ClientPage({leadXID}) {

	const { lead_xid } = useParams();
	console.log("loading lead", lead_xid);
	const {lead, owner, mutate, isLoading, isError} = useClient(lead_xid);

	console.debug(lead)
	console.log(owner)

	if (isLoading) return <div>Loading...</div>;
	if (isError) return <div>Error...</div>;



	return (
		<div>
			<SidebarProvider
				style={
					{
						"--sidebar-width": "19rem",
					}
				}
			>
				<BidSidebar selected={"welcome"}/>
				<SidebarInset>
					<div>
						<header className="flex h-16 shrink-0 items-center gap-2 px-4">
							<SidebarTrigger className="-ml-1"/>
							<Separator orientation="vertical" className="mr-2 h-4"/>
							<Breadcrumb>
								<BreadcrumbList>
									<BreadcrumbItem className="hidden md:block">
										<BreadcrumbLink href="#">
											{lead.name}
										</BreadcrumbLink>
									</BreadcrumbItem>
									<BreadcrumbSeparator className="hidden md:block"/>
									<BreadcrumbItem>
										<BreadcrumbPage>Master Bid Package</BreadcrumbPage>
									</BreadcrumbItem>
								</BreadcrumbList>
							</Breadcrumb>
						</header>
						<div className="flex flex-1 flex-col gap-4 p-4 pt-0">
							<div className={"w-full"}>
								<div className="min-h-screen">
									<div className="container mx-auto px-4 py-8 space-y-8">
										{/* Header/Logo Section */}
										<div className="flex justify-center mb-12">
											<div className="relative w-full max-w-2xl">
												<div
													className="absolute inset-0 bg-gradient-to-r from-yellow-500 to-orange-500 rounded-lg blur-2xl opacity-20"/>
												<div
													className="relative flex items-center justify-center space-x-4  rounded-lg p-6">
													<img src={"/assets/armour-25.png"} alt="Armoury"
														 className="w-20 h-20 relative "/>
													<h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-orange-500">
														ARMOUR CONSTRUCTION
													</h1>
												</div>
											</div>
										</div>

										{/* Main Image Section */}
										<Card
											className="min-h-80 w-full overflow-hidden border-zinc-800 bg-zinc-900/50 backdrop-blur-sm">
											<CardContent className="p-0">
												<div className="relative h-[500px] w-full">
													<img
														src="/assets/ss1.png"
														alt="Residential Property"

														className="object-cover"

													/>
												</div>
											</CardContent>
										</Card>

										{/* Contact Information Section */}
										<div className="grid md:grid-cols-2 gap-8 mt-8">
											{/* Prepared For Section */}
											<Card className="bg-zinc-900/50 border-zinc-800 backdrop-blur-sm">
												<CardContent className="p-6">
													<div>
														<h2 className="text-xl font-semibold text-yellow-500 mb-4">PREPARED
															FOR</h2>
														<div className="space-y-4">
															<div className="flex items-center space-x-3 text-zinc-300">
																<div className="text-lg">{lead.name}</div>
															</div>
															<div className="flex items-center space-x-3 text-zinc-400">
																<Phone className="w-4 h-4" weight={"duotone"}/>
																<a href="tel:219.895.5495"
																   className="hover:text-yellow-500 transition-colors">
																	{lead.phone}
																</a>
															</div>
															<div className="flex items-center space-x-3 text-zinc-400">
																<MapPin className="w-4 h-4" weight={"duotone"}/>
																<div>
																	<div>2505 Fairway Drive</div>
																	<div>Long Beach IN 46360</div>
																</div>
															</div>
														</div>
													</div>

												</CardContent>
											</Card>

											{/* Presented By Section */}
											<Card className="bg-zinc-900/50 border-zinc-800 backdrop-blur-sm">
												<CardContent className="p-6">
													<h2 className="text-xl font-semibold text-orange-500 mb-4">PRESENTED
														BY</h2>
													<div className="space-y-4">
														<div className="flex items-center space-x-3 text-zinc-300">
															<div className="text-lg">Jim Dietz</div>
														</div>
														<div className="flex items-center space-x-3 text-zinc-400">
															<Phone className="w-4 h-4"/>
															<a href="tel:219.755.5288"
															   className="hover:text-orange-500 transition-colors">
																219.755.5288
															</a>
														</div>
														<div className="flex items-center space-x-3 text-zinc-400">
															<Mailbox className="w-4 h-4"/>
															<a
																href="mailto:Jim@ArmourConstruction.net"
																className="hover:text-orange-500 transition-colors"
															>
																Jim@ArmourConstruction.net
															</a>
														</div>
													</div>
												</CardContent>
											</Card>
										</div>

										{/* Call to Action */}
										<div className="flex justify-center mt-12">
											<Button
												className="bg-gradient-to-r from-yellow-500 to-orange-500 font-bold text-black hover:from-yellow-600 hover:to-orange-600"
												size="lg"
											>
												Intro to Armour Construction
												<ArrowFatRight weight={"duotone"} className="ml-2 h-5 w-5" />
											</Button>
										</div>
									</div>
								</div>
							</div>


							<div className="grid auto-rows-min gap-4 md:grid-cols-3">
								<div className="aspect-video rounded-xl bg-muted/50"/>
								<div className="aspect-video rounded-xl bg-muted/50"/>
								<div className="aspect-video rounded-xl bg-muted/50"/>
							</div>
							<div className="min-h-[100vh] flex-1 rounded-xl bg-muted/50 md:min-h-min"/>
						</div>
					</div>
				</SidebarInset>
			</SidebarProvider>
		</div>

	);
}
