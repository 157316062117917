import * as React from "react"
import {BookOpen, Bot, Frame, LifeBuoy, Map, PieChart, Send, Settings2,} from "lucide-react"

import {
	Drone,
	FolderStar,
	Invoice,
	NumberSquareFour,
	NumberSquareOne,
	NumberSquareThree,
	NumberSquareTwo
} from "@phosphor-icons/react";

import {BidNavMain} from "./bid-nav-main"
import {BidNavSecondary} from "./bid-nav-secondary"
import {NavUser} from "./bid-nav-user"
import {
	Sidebar,
	SidebarContent,
	SidebarFooter,
	SidebarHeader,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
} from "../ui/sidebar"

const data = {
	user: {
		name: "shadcn",
		email: "m@example.com",
		avatar: "/avatars/shadcn.jpg",
	},
	navMain: [
		// {
		// 	title: "Bid Package",
		// 	url: "#",
		// 	icon: FolderStar,
		// 	isActive: true,
		// 	items: [
		// 		// {
		// 		// 	title: "Welcome",
		// 		// 	url: "/bid/welcome",
		// 		// },
		// 		// {
		// 		// 	title: "Intro to Armour",
		// 		// 	url: "/bid/intro-to-armour",
		// 		// },
		// 		// {
		// 		// 	title: "About your Rep",
		// 		// 	url: "/bid/about-your-rep",
		// 		// },
		// 		// {
		// 		// 	title: "About the Owner",
		// 		// 	url: "/bid/about-the-owner",
		// 		// },
		// 		// {
		// 		// 	title: "Credentials",
		// 		// 	url: "/bid/about-the-owner",
		// 		// },
		// 		// {
		// 		// 	title: "Customer Testimonials",
		// 		// 	url: "/bid/testimonials",
		// 		// },
		// 	],
		// },
		{
			title: "Start Here",
			url: "/bid",
			icon: NumberSquareOne,
			items: [],
		},
		{
			title: "Intro to Armour",
			url: "/bid/intro-to-armour",
			icon: NumberSquareTwo,
			items: [],
		},
		{
			title: "About your Rep",
			url: "/bid/about-your-rep",
			icon: NumberSquareThree,
			items: [],
		},
		{
			title: "About the Owner",
			url: "/bid/about-the-owner",
			icon: NumberSquareFour,
			items: [],
		},
		{
			title: "Credentials",
			url: "/bid/credentials",
			icon: Drone,
			items: [],
		},
		{
			title: "Customer Testimonials",
			url: "/bid/testimonials",
			icon: Drone,
			items: [],
		},
		{
			title: "Aerial CAD Diagram",
			url: "#",
			icon: Drone,
			items: [],
		},
		{
			title: "Xactimate Estimate",
			url: "#",
			icon: Invoice,
			items: [],
		}

	],
	navSecondary: [
		{
			title: "Support",
			url: "#",
			icon: LifeBuoy,
		},
		{
			title: "Feedback",
			url: "#",
			icon: Send,
		},
	],
	projects: [
		{
			name: "Design Engineering",
			url: "#",
			icon: Frame,
		},
		{
			name: "Sales & Marketing",
			url: "#",
			icon: PieChart,
		},
		{
			name: "Travel",
			url: "#",
			icon: Map,
		},
	],
}

export function BidSidebar({...props}) {
	return (
		<Sidebar variant="inset" {...props}>
			<SidebarHeader>
				<SidebarMenu>
					<SidebarMenuItem>
						<SidebarMenuButton size="lg"  asChild>
							<a href="#">
								<div className={"flex items-start gap-2 py-2"}>
									<div className="">
										<img src="/armour-icons/armoury.png" alt="Armoury" className="rounded-lg"
											 width={"50"} height={"50"}/>
									</div>
									<div className="grid flex-1 text-left text-sm leading-tight">
										<span className="truncate font-semibold">Haden & Mahalet Staab</span>
										<span className="truncate text-xs font-semibold text-green-400">AC.123.24</span>
										<div className="flex items-center gap-2 pt-1">
											<div className="flex h-2 w-2">
												<span
													className="absolute inline-flex h-2 w-2 animate-ping rounded-full bg-green-400 opacity-75"></span>
												<span
													className="relative inline-flex h-2 w-2 rounded-full bg-orange-500"></span>
											</div>
											<span
												className="text-xs text-muted-foreground">Awaiting Insurance Approval</span>
										</div>
									</div>
								</div>

							</a>
						</SidebarMenuButton>
					</SidebarMenuItem>
				</SidebarMenu>
			</SidebarHeader>
			<SidebarContent>
				<BidNavMain items={data.navMain}/>
				<BidNavSecondary items={data.navSecondary} className="mt-auto"/>
			</SidebarContent>
			<SidebarFooter>
				<NavUser user={data.user}/>
			</SidebarFooter>
		</Sidebar>
	)
}
