import React from 'react';

export default function ForgotPasswordPage() {


	return (
		<div>
			<h1>Forgot Password</h1>
		</div>
	);

}
