import useSWR from "swr";
import Lead from "../models/lead";
import {fetcher, present} from "../lib/utils";
import ArmourAPI from "../lib/armour-api";
import User from "../models/user";

export default function useClient(leadXid) {

	let { data, error, isLoading, mutate } =  useSWR( present(leadXid) ? `${ArmourAPI.endpoint}/client?lead_xid=${leadXid}` : null, fetcher);

	const lead = new Lead(data?.lead);
	const owner = new User(data?.owner);

	return {
		lead: lead,
		owner: owner,
		mutate: mutate,
		isLoading: isLoading,
		isnError: error,
	}
}
