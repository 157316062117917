import useSWR from 'swr';
import Cookies from 'js-cookie';
import { fetcher } from '../lib/utils';
import ArmourAPI from "../lib/armour-api";
import User from "../models/user";

const sessionTokenFetcher = () => {
		const token = Cookies.get('sessionToken');
		return fetcher(`${ArmourAPI.endpoint}/api/v2/me`, {
			headers:
				{ Authorization: token }
		});
};

const setter = (sessionToken, mutate) => {
	Cookies.set('sessionToken', sessionToken);
	mutate();
}

const useSessionToken = () => {
	const { data, error, mutate } = useSWR('sessionToken', sessionTokenFetcher);
	const u = new User(data);
	return {
		user: u,
		isLoading: !error && !data,
		isError: error,
		mutate: (token) => setter(token, mutate),
	};
};

export default useSessionToken;
