import React from "react";

export default function AuthLayout({ children }) {


	const handleSubmit = (e) => {

		e.preventDefault();
		const form = e.target;
		const formData = new FormData(form);

		fetch(form.action, {
			method: form.method,
			body: formData,
		})
			.then((response) => {
				if (response.ok) {
					window.location.href = "/";
				} else {
					response.json().then((json) => {
						console.log(json);
					});
				}
			})
			.catch((error) => {
				console.error("Error:", error);
			});

	}

	return (
		<div className="relative min-h-screen flex flex-col justify-center bg-obsidian-dark">
			<div
				className="absolute inset-0 bg-cover bg-center"
				style={{
					backgroundImage: "url('/assets/metal_panel.png')",
					opacity: 0.3,
				}}
			></div>

			<div className="relative z-10 flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8 -mt-16">
				<div className="w-full max-w-md">
					<a href="/" className="block mb-8">
						<img
							className="mx-auto h-24 w-auto rounded-2xl"
							src="/assets/armour-icons/armoury.png"
							alt="Logo"
						/>
					</a>

					<h2 className="mb-6 text-center text-4xl font-bold text-obsidian-light">
						Sign In
					</h2>

					<div className="px-6">
						{children}
					</div>
				</div>
			</div>
		</div>
	);
}

