import Resource from "./resource";

export default class User extends Resource {
	constructor(payload = {}) {
		super(payload);
	}

	get email() {
		return this.dto.email;
	}

	get firstName() {
		return this.dto.first_name;
	}

	get lastName() {
		return this.dto.last_name;
	}

	get fullName() {
		return `${this.firstName} ${this.lastName}`;
	}

	get phone() {
		return this.dto.phone;
	}

	get role() {
		return this.dto.role;
	}

	get active() {
		return this.dto.active;
	}


}
